<template>
  <User :project="vid" embed></User>
</template>

<script>
import User from '../../../flux/views/Users.vue';

export default {
  name: 'financialclose_varienceanalysis_user',
  // props: ['props'],
  data: function () {
    return {
      vid: "",
    }
  },
  components: {
    User
  },
  created() {
    this.vid = this.$route.params.vid;
    console.log(this.vid);
  },
  activated() {
    this.vid = this.$route.params.vid;
  },

  methods: {
    refreshData() {
    },

  },
  computed: {
  },
  watch: {
  }
}
</script>

